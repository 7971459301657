import React from 'react';
import TypingText from '../../components/TypingText';
import { loadData, setData, updateData } from '../../Storage';
import { useGlobalState } from '../../AppState';
import { ReactComponent as SendIcon } from '../../assets/send.svg';

const ActionProvider = ({
  createChatBotMessage,
  setState,
  children,
  onFormEnd,
}: any) => {

  const [formComplete, setFormComplete] = React.useState(false);
  const [textInputEnabled, setTextInputEnabled] = React.useState(true);
  const [answers, setAnswers] = React.useState<any>([]);
  const [questions, setQuestions] = React.useState<any>([]);
  const { currentIdx, setCurrentIdx } = useGlobalState();

  const sendIconRef: any = React.useRef();

  React.useEffect(() => {
    let data = loadData();

    if (!data) {
      setQuestions(setData());
    } else {
      setQuestions(data as any);
    }

    const elements = document.getElementsByClassName('react-chatbot-kit-chat-input');
    const firstElement = elements[0];

    (firstElement as any).enterKeyHint = 'send';
    (firstElement as any).autocomplete = 'off';

    sendIconRef.current.classList.add('hidden');
    firstElement.addEventListener('focus', () => {
      sendIconRef.current.classList.remove('hidden');
    });

    firstElement.addEventListener('blur', async () => {
      await setTimeout(() => {
        sendIconRef.current.classList.add('hidden');
      }, 300);
    });

    setCurrentIdx(1);
    setTextInputEnabled(false);
    
  }, [])

  React.useEffect(() => {
    const elements = document.getElementsByClassName('react-chatbot-kit-chat-input');
    const firstElement = elements[0];
    if (formComplete) {
      (firstElement as any).hidden = true;
    } else {
      if (firstElement) {
        (firstElement as any).value = '';
        (firstElement as any).hidden = !textInputEnabled;
      }
    }

  }, [textInputEnabled, formComplete]);

  React.useEffect(() => {
    if (formComplete) {
      onFormEnd();
    }
  }, [formComplete]);

  const getOptions = () => {
    let currentQuestion = questions[currentIdx - 1];
    if (currentQuestion) {
      return (
        currentQuestion.options?.map((x: any) => ({ value: x, label: x }))
      )
    } else {
      return ([]);
    }
  }

  const getValue = () => {
    let val = questions[currentIdx - 1]?.answer ?? '';
    return { value: val, label: val }
  }

  const generateId = () => {
    return Math.floor(Math.random() * 1000000000)
  }

  const handleSkip = () => {
    if (questions) {
      const qt = questions?.reduce((acc: any[], q: any) => {
        acc = [
          ...acc,
          {
            id: generateId(),
            message: q?.value,
            type: 'bot'
          },
          {
            id: generateId(),
            message: q?.answer,
            type: 'user'
          }
        ]
        return acc;
      }, []);

      setState((prev: any) => {
        return {
          ...prev,
          messages: [...qt],
        }
      });

      setFormComplete(true);
    }
  }

  const handleUserResponse = (answer: string, customBtn = false) => {

    const elements = document.getElementsByClassName('react-chatbot-kit-chat-input');
    const firstElement = elements[0];

    if (customBtn) {
      let val = (firstElement as any).value
      if(val.toUpperCase() === 'SKIP') {
        handleSkip();
        return
      }  
      if (!val) {
        setState((prev: any) => {
          let msgs = prev.messages.slice(0, prev.messages.length - 1)
          return {
            ...prev,
            messages: [...msgs, {
              id: generateId(),
              message: questions[currentIdx - 1]?.answer,
              type: 'user'
            }],
          }
        });
      } else {
        updateData(currentIdx - 1, val);
        setState((prev: any) => {
          return {
            ...prev,
            messages: [...prev.messages, {
              id: generateId(),
              message: val,
              type: 'user'
            }],
          }
        });  
      }
    } else {
      if (!answer) {
        setState((prev: any) => {
          let msgs = prev.messages.slice(0, prev.messages.length - 1)
          return {
            ...prev,
            messages: [...msgs, {
              id: generateId(),
              message: questions[currentIdx - 1]?.answer,
              type: 'user'
            }],
          }
        });
      } else {
        updateData(currentIdx - 1, answer)
      }
    }

    setAnswers([...answers, answer])
    let currentQuestion = questions[currentIdx];
    if (currentQuestion) {
      let message = null;
      if (currentQuestion.type === 'select') {
        setTextInputEnabled(false);
        message = createChatBotMessage(
          <TypingText text={currentQuestion.value} />,
          {
            widget: 'select',
            loading: false,
            delay: 0,
          }
        );
      } else {
        setTextInputEnabled(true);
        message = createChatBotMessage(<TypingText text={currentQuestion.value} />);
      }
      updateChatbotState(message);
      setCurrentIdx(currentIdx + 1);
    } else {
      setFormComplete(true);
    }
  };


  // const handleSelect = (answer: string) => {
  //   const botMessage = createChatBotMessage(
  //     "Plese select one of the options",
  //     {
  //       widget: 'select',
  //     }
  //   );
  //   updateChatbotState(botMessage);
  // };

  const updateChatbotState = (botMessage: any) => {
    setState((prev: any) => {
      return {
        ...prev,
        messages: [...prev.messages, botMessage],
      }
    });
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleUserResponse,
            updateChatbotState,
            handleSkip,
            // handleSelect,
            getOptions,
            getValue
          },
        });
      })}
      <SendIcon
        ref={sendIconRef}
        onClick={() => handleUserResponse('', true)}
        className='sendIcon' />

      <div className='lineWrapper'>
        <div className={ textInputEnabled? 'lineBlue':'line'}/>
      </div>   
    </div>
  );
};

export default ActionProvider;