import { createChatBotMessage, createClientMessage } from 'react-chatbot-kit';
import CustomSelect from '../../components/CustomSelect';
import TypingText from '../../components/TypingText';

const config = {
  loading: false,
  initialMessages: [
    createClientMessage(<TypingText text="Let's get started"/>),
    createChatBotMessage(
      <>
        <p> Welcome to Can You Play! </p>
         <iframe paddingTop='20px' paddngBottom='20px' width="100%" height="200px" src="https://www.youtube.com/embed/WZUzYDTTPws?si=HFArCNuseHf96LzM&amp;controls=0&amp;start=41" title="Stretch w/a Leader; Bo Nemelka 8.14.23" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>
        <p>Tell me a few things about your child...</p>       
      </>
    ),
    createChatBotMessage(
      <TypingText text="What is your child’s age?"/>,
      {
        widget: 'select',
        loading: false,
        delay: 0,
      }      
    )
  ],
  widgets: [
    {
      widgetName: 'select',
      widgetFunc: (props) => <CustomSelect {...props} />,
    },
    {
      widgetName: 'showTyping',
      widgetFunc: (props) => <TypingText {...props} />,
    }
  ],
  inputType: 'select'
};

export default config;