import styles from "./Button.module.scss"
import cx from "classnames";


interface IButton {
  children: React.ReactNode;
  type?: 'primary' | 'secondary' | 'tertiary' | string;
  outline?: boolean;
  onClick?: () => void;
  width?: string;
  color?: string;
  selected?: boolean;
  bgcolor?: string;
}

const Button: React.FC<IButton> = ({
  children,
  type = 'primary',
  onClick,
  width = 'auto',
  selected = false,
  bgcolor
}) => {
  return(
    <button
      style={{
        width: width,
        backgroundColor: bgcolor ?? ''
      }}
      onClick={onClick}
      className={cx({[styles.primary] : type === 'primary', 
                     [styles.secondary] : type === 'secondary', 
                     [styles.tertiary] : type === 'tertiary', 
                     [styles.transparent] : type === 'transparent',
                     [styles.confirm] : type === 'confirm',
                     [styles.cancel] : type === 'cancel',
                     [styles.round] : type === 'round',
                     [styles.pressme] : type === 'pressme',

                    [styles.selected] : selected})}
    >
      {children}
    </button>
  )
}

export default Button;