import { useState, useEffect } from 'react';

const getViewWidth = () => window.innerWidth;

export const useViewWidth = () => {
  const [viewWidth, setViewWidth] = useState(getViewWidth());

  useEffect(() => {
    const handleResize = () => setViewWidth(getViewWidth());
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return viewWidth;
};
