import Modal from '../../components/Modal/Modal';
import styles from './chatmodal.module.scss';
import Text from '../../components/Text';
import { ReactComponent as WhiteLogo } from '../../assets/white_logo.svg';
import React from 'react';
import Button from '../../components/Button';


interface IChatModal {
  isOpen: boolean;
  handleClose: (status: boolean) => void;
  callback: () => void;
}

const ChatModal: React.FC<IChatModal> = ({
  isOpen,
  handleClose,
  callback
}) => {

  const [messages, setMessages] =  React.useState<any[]>([
    {
      msg: "Hi Katie! Your family's profile is so fun - I'd love to tell you more about my daughter Attie", 
      type:'usr', 
      time:'10:00'},
    {
      msg: "Hi Kirsten! Nice to chat with you. Does Attie like Thanksgiving Point? Eli loves it there. Maybe we could meet up?", 
      type: 'peer',
      time:'10:02'
    },
    {
      msg: "I put our availabillity into the CYP schedulling tool. Given Eli's sensory needs, maybe we could meet for 45 minutes or so this first time and go from there?", 
      type: 'peer',
      time:'10:04'
    }
  ])
  const [currentMessages, setCurrentMessages] = React.useState<any[]>([])
  const [showBtn, setShowBtn] = React.useState<boolean>(false)
  const scrollableDivRef:any = React.useRef();

  React.useEffect(() => {

    setTimeout(() => {
      setCurrentMessages([...messages.slice(0, 1)])
       setTimeout(() => {
        setCurrentMessages([...messages.slice(0, 2)])
        setTimeout(() => {
          setCurrentMessages([...messages])
          setTimeout(() => {
            setShowBtn(true)
          }, 1000)
        }, 1000)
       }, 1000)
    }, 1000)
  }, [])

  React.useEffect(() => {
    const scrollableDiv = scrollableDivRef.current;
    if(scrollableDiv)
      scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
  }, [currentMessages, showBtn]);

  return (
    <Modal
      handleClose={() => {
        handleClose(false);
      }}
      isOpen={isOpen}
    >
      <div className={styles.wrapper}>
        <div className={styles.header}><Text color='#233752' fontSize={23} fontWeight={900}>Chat with Your Match</Text></div>
        <div className={styles.contentWrapper} ref={scrollableDivRef}>
          <div className={styles.logoWrapper}>
            <WhiteLogo width='50%' />
          </div>
          {currentMessages.map((msg, idx) => (
            <div key={idx} className={(msg.type === 'peer')? styles.peerMsg : styles.userMsg}>
              <Text type='chat'>
                  {msg.msg}
              </Text>
              <div className={styles.timestamp}>
                <Text type='small'>{msg.time}</Text>
              </div>
          </div>
          ))}
          {(showBtn) && <Button bgcolor='#233752' onClick={callback}>Schedule Play Date</Button>}
        </div>
      </div>
    </Modal>
  )

}

export default ChatModal;
