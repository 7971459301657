import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { GlobalStateProvider } from './AppState';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>    
     
      <Auth0Provider                
        domain="auth-dev.cj-lee.com"
        clientId="gAkDn9TJlEmDqsc62MYdCiNc2tVP0qLp"        
        authorizationParams={{
          redirect_uri: window.location.origin,          
          issuer: "https://auth-dev.cj-lee.com/",
          audience: "https://dev-r3pn41if.us.auth0.com/api/v2/",          
          scope: "read:current_user read:current_user_metadata update:current_user_metadata"
        }}                         
      >    
       <GlobalStateProvider>
        <App/>
       </GlobalStateProvider>
    </Auth0Provider>     
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
