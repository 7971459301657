import Text from '../../../Text'
import Button from '../../../Button'
import styles from './popupContent.module.scss'
import { Popup } from 'react-map-gl'
import './styles.scss';



interface IMyPopup {
  family: any
  setIsPopupOpen: (val: boolean) => void
  detailsCallback: () => void
}

const MyPopup: React.FC<IMyPopup> = ({ family, setIsPopupOpen, detailsCallback }) => {

  return (
    <Popup
      latitude={40.688214545570496}
      longitude={-111.80327083232922}
      onClose={() => setIsPopupOpen(false)}
      closeOnClick={false}
      style={{
        maxWidth: 'fit-content',
        padding:'30px',
        display:'flex',
        justifyContent:'center',
        alignContent:'center'

      }}
    >
      <div className={styles.popupWrapper}>
        <div className={styles.headerWrapper}>
          <img
            width='70px'
            height='70px'
            loading="lazy"
            className={styles.picture}
            src={family?.pic_url} 
          />
          <div
            className={styles.headerTextWrapper}
          >
            <Text type='small' bold>The {family?.family_name} Family</Text>
            <Text type='small'>{`${family?.city}, ${family?.state}`.toUpperCase()}</Text>
            <Text type='small'>Available {family?.avail_tod}</Text>
          </div>
        </div>
        <div className={styles.listWrapper}>
          <ul>
            <li><Text type='small'>{family?.kids_description}</Text></li>
            <li><Text type='small'>{family?.kids_interest}</Text></li>
            <li><Text type='small'>{family?.goal}</Text></li>
          </ul>
        </div>
        <div style={{ paddingTop: '20px', display: 'flex', justifyContent: 'center' }}>
          <Button width='70%' onClick={detailsCallback}><b style={{ fontWeight: 600 }}>More</b></Button>
        </div>
      </div>
    </Popup>

  )

}

export default MyPopup;