import React, { forwardRef } from 'react';
import { Layer, Map, Marker, Source } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import CustomMarker from './components/CustomMarker';
import MyPopup from './components/PopupContent/MyPopup';




const createCircleCoordinates = (center: [number, number], radius: number, points: number) => {
  const coords = [];
  const [lng, lat] = center;
  for (let i = 0; i < points; i++) {
    const angle = (i * 360) / points;
    const offsetLng = lng + (radius / 81) * Math.cos((angle * Math.PI) / 180);
    const offsetLat = lat + (radius / 111) * Math.sin((angle * Math.PI) / 180);
    coords.push([offsetLng, offsetLat]);
  }
  coords.push(coords[0]); 
  return coords;
};


const center:any = [-111.80327083232922, 40.688214545570496];
const radius = 1; 
const points = 32;

const circleCoordinates = createCircleCoordinates(center, radius, points);

const geojson = {
  type: 'Feature',
  geometry: {
    type: 'Polygon',
    coordinates: [circleCoordinates],
  },
};

const layerStyle = {
  id: 'polygon',
  type: 'fill',
  paint: {
    'fill-color': '#02BBFF',
    'fill-opacity': 0.2,
  },
};

interface IBackgroundComponent {
  children: React.ReactNode
  onLoad: () => void
  familiesData: any
  selectedFamily: number
  selectedFamilyCarousel: (direction: string) => void
  mapOn: boolean
  setModalOpen: (val:boolean) => void
}

const BackgroundComponent = ({ children, mapOn, setModalOpen, onLoad, familiesData, selectedFamily, selectedFamilyCarousel }: IBackgroundComponent,) => {
  const mapRef = React.useRef<mapboxgl.Map | undefined>(undefined)
  const popupRef = React.useRef<any>()
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);

  const flyToLocation = (long: number, lat: number, pitch: number) => {
    if (mapRef.current !== undefined) {
      mapRef.current.flyTo({
        center: [long, lat],
        zoom: 15,
        speed: 0.5,
        curve: 1.5,
        bearing: 0,
        pitch: pitch,
        essential: true,
      }).setFog({
        'color': 'rgb(220, 159, 159)', 
        'high-color': 'rgb(36, 92, 223)', 
        'horizon-blend': 0.4 
      }).once('moveend', () => {
        onLoad();
        setIsPopupOpen(true);
      });
    }
  }

  const styles = {
    container: {
      position: 'relative' as 'relative',
      height: '100vh',
      width: '100%'
    },
    map: {
      position: 'absolute' as 'absolute',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      zIndex: 1,
    },
    overlay: {
      position: 'relative' as 'relative',
      zIndex: 2,
      width: mapOn ? '0' : '100%',
      height: mapOn ? '0' : '100%',
    },
  }

  const onDetailsClicked = () => {
    setModalOpen(true)
  }
  
  return(
    <div 
      style={styles.container}>
      {(mapOn) &&
        <Map
          ref={mapRef as any}
          style={styles.map}
          onLoad={() => flyToLocation(-111.80327083232922, 40.688214545570496, 0)}       
          onClick={() => {setIsPopupOpen(false)}}
          initialViewState={{
            longitude: -122.60,
            latitude: 37.792,
            zoom: 11.8,
            bearing: 100,
            pitch: 65,
          }}
          mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
          mapboxAccessToken='pk.eyJ1IjoicGVkcm9leHBsb3JlciIsImEiOiJjbTEwc3B4NjQwbG9hMm5vcnNzYjV3OWJrIn0.NHEuhLvyUA_JK34QPSfN9g'
        >
          <div
            onClick={(e) => {
              e.stopPropagation()
              setIsPopupOpen(true)
            }}
          >
            <Marker
              key='family location'
              longitude={-111.80327083232922}
              popup={popupRef.current}
            
              latitude={40.688214545570496}>
              <div
                style={{ cursor: 'pointer' }}
              >
                <CustomMarker open={isPopupOpen} selected={selectedFamily} selectedFamilyCarousel={selectedFamilyCarousel}/>
              </div>
            </Marker>
          </div>
          {isPopupOpen && (
            <MyPopup 
              detailsCallback={onDetailsClicked}
              setIsPopupOpen={setIsPopupOpen}
              family={familiesData[selectedFamily]}
            />
          )}
          <Source id="polygon-source" type="geojson" data={geojson}>
            <Layer {...(layerStyle as any)} />
          </Source>
        </Map>
      }
      <div style={styles.overlay}>
        {children}
      </div>
    </div>
  );
};


export default BackgroundComponent;