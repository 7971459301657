import React, { useState } from 'react';
import styles from './tooltip.module.scss';


interface ITooltip {
  children: React.ReactElement;
  content: string;
}

const Tooltip: React.FC<ITooltip> = ({children, content}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={styles.hoverElement}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
      {isHovered && <div className={styles.tooltip}>{content}</div>}
    </div>
  );
};

export default Tooltip;
