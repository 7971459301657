
import { ReactComponent as Left } from '../../assets/arrow_left_alt.svg';
import { ReactComponent as Right } from '../../assets/arrow_right_alt.svg';
import Text from '../Text';
import styles from './navigate.module.scss'


interface INavigate {
  handleLeft: (e: any) => void;
  handleRight: (e: any) => void;
  selected: number;
  total: number
}

const Navigate: React.FC<INavigate> = ({handleLeft, handleRight, selected, total}) => {

  return(
    <div
      className={styles.markerWrapper}>
      <div
        className={styles.btnLeft}
        onClick={handleLeft}
        onDoubleClick={(e) => e.stopPropagation()}
        onTouchStart={(e) => e.stopPropagation()}
      ><Left /></div>
      <div
        className={styles.btnRight}
        onClick={handleRight}
        onDoubleClick={(e) => e.stopPropagation()}
        onTouchStart={(e) => e.stopPropagation()}

      ><Right /></div>
      <div className={styles.pinpoint}/>
      <Text>{selected} of {total}</Text>
    </div> 
  )
}

export default Navigate;