import styles from './Header.module.scss'; 
import React from 'react';
import Text from '../Text';
import { ReactComponent as Colapse } from '../../assets/colapse.svg';
import cx from 'classnames';

interface IHeader {
  togglePane: () => void;
  isOpen: boolean;
  mapOn: boolean;
}

const Header: React.FC<IHeader> = ({togglePane, isOpen, mapOn}) => {

  return(    
    <>
    <div className={styles.headerBackground}></div>
    <div className={styles.HeaderWrapper}>
     <div className={cx(styles.sidepaneToggle, {[styles.hidden] : isOpen})} onClick={togglePane}>
        <Colapse fill={(mapOn? 'white' : '#717070')} width={28} height={28} />
      </div>
    </div> 
    </>
  )
}

export default Header
