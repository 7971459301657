import React from 'react';

const MessageParser = ({ children, actions }:any) => {


  const parse = (message: string) => {
    if(message.toUpperCase() === 'SKIP') {
      actions.handleSkip()
    } else {
      actions.handleUserResponse(message);      
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;