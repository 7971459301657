
import { formatMoney } from '../../helpers';
import styles from './Text.module.scss';
import cx from 'classnames';
export interface IText {
  type?: string;
  bold?: boolean;
  textAlign?: 'left' | 'center' | 'right' | 'unset' | 'justify';
  textTransform?:
    | 'none'
    | 'capitalize'
    | 'uppercase'
    | 'lowercase'
    | 'full-width'
    | 'full-size-kana';
  truncate?: boolean;
  margin?: string;
  onClick?: () => void;
  children: any;
  textAligin?: any;
  width?: any,
  color?: string,
  fontSize?: number,
  fontWeight?: number
}

const Text: React.FC<IText> = ({
  bold = false,
  children,
  type = 'primary',
  textAligin = 'left',
  width = '100%',
  margin = '0',
  color,
  fontSize,
  fontWeight,
  ...rest
}) => {
  return(
  <p
    style={{
      textAlign: textAligin,
      width: width,
      margin: margin,
      color: color ?? '',
      fontSize: fontSize ?? '',
      fontWeight: fontWeight ?? ''
    }}
    className={cx({
      [styles.primary] : type === 'primary',
      [styles.secondary] : type === 'secondary',
      [styles.small] : type === 'small',
      [styles.currency] : type === 'currency',
      [styles.huge] : type === 'huge',
      [styles.big] : type === 'big',
      [styles.italic] : type === 'italic',
      [styles.bigItalic] : type === 'bigItalic',
      [styles.bigItalic] : type === 'bigItalic',
      [styles.headerWithShaddow] : type === 'headerWithShaddow',
      [styles.header] : type === 'header',
      [styles.subHeader] : type === 'subHeader',
      [styles.smallHeader] : type === 'smallHeader',
      [styles.chat] : type === 'chat',
      [styles.bold] : bold,
    }, styles.default)}
    {...rest}
  >
    {(type === 'currency')? formatMoney(children) : children}
  </p>)
}

export default Text;