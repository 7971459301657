
export const formatMoney = (value: string) => {
  if (!value) return ''

  value = value+''
  value = value.replace(/[^0-9-]+/g, '')
  value = `$ ${value.replace(/([0-9]{2})$/g, ',$1')}`
  if (value.length > 6) value = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  return value
}
