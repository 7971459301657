import Modal from "../../components/Modal/Modal";
import Text from '../../components/Text';
import styles from './loadingModal.module.scss'


interface ILoadingModal {
  isOpen: boolean;
  handleClose: () => void;
}

const LoadingModal: React.FC<ILoadingModal> = ({
  isOpen,
  handleClose
}) => {
  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      animated
    >
      <div className={styles.wrapper}>
        <div className={styles.mainContentWrapper}>
          <Text bold textAligin='center' margin="0 0 20px 0">
            Matching in Progress
          </Text>
          {/* <Text bold textAligin='center'>
            This is according to your initial preferences that you could change anytime in the future.
          </Text> */}
          <div className={styles.loadingIcon}>
            <svg 
              width="50" 
              height="50" 
              viewBox="0 0 105 105" 
              fill="#FF50E0" 
              data-testid="grid-svg"><circle cx="12.5" cy="12.5" r="5"><animate attributeName="fill-opacity" begin="0s" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="12.5" cy="52.5" r="5"><animate attributeName="fill-opacity" begin="100ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="52.5" cy="12.5" r="5"><animate attributeName="fill-opacity" begin="300ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="52.5" cy="52.5" r="5"><animate attributeName="fill-opacity" begin="600ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="92.5" cy="12.5" r="5"><animate attributeName="fill-opacity" begin="800ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="92.5" cy="52.5" r="5"><animate attributeName="fill-opacity" begin="400ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="12.5" cy="92.5" r="5"><animate attributeName="fill-opacity" begin="700ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="52.5" cy="92.5" r="5"><animate attributeName="fill-opacity" begin="500ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="92.5" cy="92.5" r="5"><animate attributeName="fill-opacity" begin="200ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle></svg>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default LoadingModal;

