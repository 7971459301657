import styles from './slider.module.scss'
import React, { useState } from 'react'
import Text from '../Text'


interface ISlider {
  labelLeft?: string,
  labelRight?: string
}

const Slider:React.FC<ISlider> = ({
  labelLeft = '', 
  labelRight = ''
}) => {
  const [value, setValue] = useState(50)

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  return (
    <div className={styles.wrapper}>
      <input
        className={styles.slider}
        type="range"
        min="0"
        max="100"
        value={value}
        onChange={handleChange}
      />
      <div className={styles.labels}>
        <Text type='italic' color='white'>{labelLeft}</Text>
        <Text textAligin='right' type='italic' color='white'>{labelRight}</Text>
      </div>

    </div>
  );
};

export default Slider;
