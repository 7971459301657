import React from 'react';
import styles from './SidePane.module.scss';
import { ReactComponent as Colapse } from '../../assets/colapse.svg';
import cx from "classnames";

interface ISidePage {
  children: any;
  sidepaneContent: any;
  isOpen: boolean;
  togglePane: () => void;
}
const SidePane: React.FC<ISidePage> = ({
  children, 
  isOpen, 
  sidepaneContent,
  togglePane
}) => {

  React.useEffect(() => {

  }, [sidepaneContent]);

  let touchStartX: number = 0;

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX = e.touches[0].clientX;
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    const touchEndX = e.changedTouches[0].clientX;
    const swipeDistance = touchEndX - touchStartX;    
    if (swipeDistance > 100) { 
      // openPane();
    }
    if (swipeDistance < -100) { 
      // closePane();
    }
  };

  return (
    <div className={styles.contentWrapper} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
      <div className={cx(styles.sidepaneWrapper, {[styles.hidden] : !isOpen})}>
        <div className={cx(styles.sidepaneToggle)} onClick={togglePane}>
          <Colapse fill='#717070' width={28} height={28} />
        </div>
        {sidepaneContent()}
      </div>
      <div className={cx(styles.childrenWrapper, {[styles.childrenWrapper_fw]: !isOpen})}>
        {children}
      </div>
    </div>
  );
};

export default SidePane;
