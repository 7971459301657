import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'; 
import Home from './Modules/Home';
import AuthGuard from './AuthGuard';
import ProtectedRoute from './ProtectedRoute';
import ProfilePage from './Modules/ProfilePage';

const App: React.FC = () => (
  <Router>
     <Routes>
      <Route path="*" element={<AuthGuard/>} />
      <Route path="home" element={
        <ProtectedRoute><Home/></ProtectedRoute>
      } />
       <Route path="profile" element={
        <ProtectedRoute><ProfilePage/></ProtectedRoute>
      } />
    </Routes>
  </Router>
);

export default App;
