import React from 'react';
import ReactDOM from 'react-dom';
import styles from './Modal.module.scss';
import Text from '../Text';

interface IModal {
  isOpen: boolean;
  children: React.ReactElement;
  handleClose: () => void;
  animated?: boolean;
  backgroundOn?: boolean;
}

const modalRoot = document.getElementById('modal')

const Modal: React.FC<IModal> = ({
  isOpen,
  children,
  handleClose,
  animated = false,
  backgroundOn = false
}) => {
  const [domReady, setDomReady] = React.useState(false);

  React.useEffect(() => {
    setDomReady(true)
  });

  React.useEffect(() => {
    const body = document.querySelector<HTMLInputElement>('body')
    const html = document.querySelector<HTMLInputElement>('html')
    if (isOpen) {
      body!.style.overflow = 'hidden'
      html!.style.overflowY = 'hidden'
    }
    function handleOverflow() {
      body!.style.overflow = 'unset'
      html!.style.overflowY = 'unset'
    }
    return () => handleOverflow()
  }, [isOpen])

  React.useEffect(() => {
    const handler = function handleEscClose(event: KeyboardEvent) {
      if (isOpen && event.key === 'Escape') {
        handleClose()
      }
    }
    document.addEventListener('keyup', handler)
    return () => document.removeEventListener('keyup', handler)
  }, [handleClose, isOpen])


  const renderHeader = () => {
    return (
      <>
        <Text
          type='small'
          onClick={handleClose}
        >close</Text>
      </>
    )
  }

  return modalRoot && domReady && isOpen ?
    ReactDOM.createPortal(
      <div className={styles.modalWrapper}>
        <div 
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              handleClose();
            }
          }}
          className={styles.modalOverlay}>
          <div className={animated? styles.modalContainerAnimated : styles.modalContainer }>
            <div className={styles.headerComponent}>{renderHeader()}</div>
              <div className={styles.modalContent}>
                {children}
              </div>
          </div>
        </div>
      </div>,
      modalRoot 
    ) : null;
}

export default Modal;


