import React, { createContext, useContext, useState, ReactNode } from 'react';

interface GlobalStateContextType {
  currentIdx: number;
  setCurrentIdx: React.Dispatch<React.SetStateAction<number>>;
}

const GlobalStateContext = createContext<GlobalStateContextType | undefined>(undefined);

interface GlobalStateProviderProps {
  children: ReactNode;
}

export const GlobalStateProvider = ({ children }: GlobalStateProviderProps) => {
  // Define the shared state and its updater function
  const [currentIdx, setCurrentIdx] = useState<number>(1);

  return (
    <GlobalStateContext.Provider value={{ currentIdx, setCurrentIdx }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

// Hook to use the global state in components
export const useGlobalState = (): GlobalStateContextType => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("currentQuestion must be used within a GlobalStateProvider");
  }
  return context;
};
