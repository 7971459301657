import React from 'react';
import { ReactComponent as Left } from '../../../../assets/arrow_left_alt.svg';
import { ReactComponent as Right } from '../../../../assets/arrow_right_alt.svg';
import Text from '../../../Text';

import styles from './customMarker.module.scss'


interface ICustomMarker {
  selected: number
  selectedFamilyCarousel: (selected: string) => void
  open: boolean
}

const CustomMarker: React.FC<ICustomMarker> = ({ selected, selectedFamilyCarousel, open }) => {

  const [isHovered, setIsHovered] = React.useState(false);

  const handleLeft = (e: any) => {
    e.stopPropagation();

    selectedFamilyCarousel('l')
  }

  const handleRight = (e: any) => {
    e.stopPropagation();
    selectedFamilyCarousel('r')
  }

  return (
    (open || isHovered) ?
      <div
        onMouseLeave={() => setIsHovered(false)}
        className={styles.markerWrapper}>
        <div
          className={styles.btnLeft}
          onClick={handleLeft}
          onDoubleClick={(e) => e.stopPropagation()}
          onTouchStart={(e) => e.stopPropagation()}
        ><Left /></div>
        <div
          className={styles.btnRight}
          onClick={handleRight}
          onDoubleClick={(e) => e.stopPropagation()}
          onTouchStart={(e) => e.stopPropagation()}

        ><Right /></div>
        <div className={styles.pinpoint}/>
        <Text>{selected + 1} of 5</Text>
      </div> :
      <div
        onMouseEnter={() => setIsHovered(true)}
        className={styles.markerWrapperClosed}
      >
      <div className={styles.pinpoint}/>
      <div className={styles.content}><Text textAligin='center'>{5}</Text></div>
      </div>
  );
}

export default CustomMarker;
