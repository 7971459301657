import React, { useEffect, useState } from 'react';

const TypingText = ({ text, speed = 30 }: any) => {
  const [displayedText, setDisplayedText] = useState('|');

  useEffect(() => {
    let index = 1;
    const intervalId = setInterval(() => {
      setDisplayedText(text.slice(0, index));
      index++;

      if (index > text.length) clearInterval(intervalId);
    }, speed);

    return () => clearInterval(intervalId);
  }, [text, speed]);

  return <span>{displayedText}</span>;
};

export default TypingText;
