import Modal from "../../components/Modal/Modal"
import styles from "./matchModal.module.scss";
import Text from '../../components/Text';
import { ReactComponent as Face } from '../../assets/face.svg';
import Tooltip from "../../components/Tooltip";
import Button from "../../components/Button";
import { useViewWidth } from "../../hooks/useViewWidth";
import Navigate from "../../components/Navigate";


interface IMatchModal {
  isOpen: boolean;
  handleClose: (status: boolean) => void;
  data: any;
  selectedFamilyCarousel: (selected: string) => void;
  selectedFamily: number;
}

const MatchModal: React.FC<IMatchModal> = ({
  isOpen,
  handleClose,
  data,
  selectedFamilyCarousel,
  selectedFamily
}) => {

  const handleLeft = (e: any) => {
    e.stopPropagation();

    selectedFamilyCarousel('l')
  }

  const handleRight = (e: any) => {
    e.stopPropagation();
    selectedFamilyCarousel('r')
  }

  const viewWidth = useViewWidth();

  const renderKidsIcons = () => {
    if(data?.kids_array) {
      let arr = JSON.parse(data.kids_array);
      return(
        arr.map((kid: any, index: number) => {
          return(
            <Tooltip key={index} content={`${kid?.name}, ${kid?.age} yo`}>
              <Face className={styles.childIcon} fill={kid?.gender === 'f'? "#FF50E0" : "#4903E2"} width={30}/>
            </Tooltip>)
        })
      )
    }

    return null;
  }

  const generateDescription = () => {
    let kids = ''
    if(data?.kids_array) {
      let arr = JSON.parse(data.kids_array);
      kids = arr.slice(0, -1).reduce((acc: string, obj: any) => acc + obj.name + ", ", "");
      kids = kids + " and " + arr.at(-1).name
    }

    return `Meet the ${data?.family_name} family: ${data?.parent1_fname}, ${data?.parent2_fname}, ${kids}.`
  }

  return(
    <Modal
      handleClose={() => {handleClose(false)}} 
      isOpen={isOpen}
    >
      <div className={styles.wrapper}>
        <div className={styles.rowWrapper}>
          <img loading="lazy" className={styles.picture} 
            src={data?.pic_url}/>
          <div>
            <Text type='header'>Meet the {data?.family_name} family</Text>
            {renderKidsIcons()}    
          </div>
        </div>
        <div className={styles.mainContentWrapper}>
          <Text type='subHeader'>{generateDescription()}</Text>
          <br/>
          <br/>
          <Text>{data?.detail_description}</Text>
        </div>
        
        <div style={{position:'absolute', bottom:'10px', right:'10px'}}>
          <Button 
            onClick={() => {
              handleClose(true)
            }}
            width={(viewWidth < 480)?'150px':'250px'}>Can You Play?</Button>
        </div>

        <div style={{position:'absolute', bottom:'10px', left:'30px'}}>
          <Navigate handleLeft={handleLeft} handleRight={handleRight} selected={selectedFamily + 1} total={5}/>
        </div>
      </div>
    </Modal>
  )
}

export default MatchModal;