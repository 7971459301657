import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";



function ProtectedRoute({ children }: any) {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        loginWithRedirect({
          appState: {
            returnTo: "/home",
          },
        });
      }
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, navigate]);

  if (isLoading || !isAuthenticated) {
    return <div>Loading...</div>;
  }

  return children;
}

export default ProtectedRoute;
