import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

function AuthGuard() {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        navigate("/home");
      } else {
        loginWithRedirect({
          appState: {
            returnTo: "/home",
          },
        });
      }
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, navigate]);

  return null;
}

export default AuthGuard;
