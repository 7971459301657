import React from 'react'
import Select from 'react-select'
import styles from './Select.module.scss'
import { useViewWidth } from '../../hooks/useViewWidth';

const CustomSelect = (props:any) => {
  const [opts, setOpts] = React.useState([]);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [selected, setSelected] = React.useState<any>(null);
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const viewWidth = useViewWidth();

  const optionSelected = (e: any) => {
    (props as any).actions.handleUserResponse(e.value);
    setSelected({value: e.value, label: e.value});
    setIsDisabled(true);
  }

  React.useEffect(() => {
    let options = (props as any).actions.getOptions();
    if(options)
      setOpts(options);

    let sl = (props as any).actions.getValue();
    if(sl?.value)
      setSelected(sl);
  }, []);
  
  const handleKeyDown = (e: any) => {
    if(e.code === 'Enter' && !menuOpen) {
      (props as any).actions.handleUserResponse(selected?.value);
      setIsDisabled(true);
    }
  }
  
  return(
    <div className={styles.wrapper}>
      <Select 
        onMenuOpen={() => setMenuOpen(true)}
        onMenuClose={() => setMenuOpen(false)}
        autoFocus
        menuPlacement='top'
        styles={{
          control: (provided, state) => ({
            ...provided,
            backgroundColor: 'rgba(230, 239, 252, 0.5019607843)',
            height:'41px',
            width:(viewWidth < 480)?'52vw':'14vw',
            border: '1px solid #1C83C1;'
          }),
          menu: (provided) => ({
            ...provided,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.data.value === 'Autism Spectrum Disorder (ASD)' ? 'rgba(73, 3, 226, .3)' : 
                             state.data.value === '1 yr - 2 yr' ? 'rgba(73, 3, 226, .3)' : 
                             state.data.value === 'Roblox/Minecraft' ? 'rgba(73, 3, 226, .3)' : 
                             state.data.value === 'Encouraging imaginative play' ? 'rgba(73, 3, 226, .3)' :                                                          
                             provided.backgroundColor,
          })
        }}
        options={opts} 
        value={selected}
        onChange={optionSelected}
        onKeyDown={handleKeyDown}
        isSearchable={false}
        isDisabled={isDisabled}
      />
    </div>
  )
}

export default CustomSelect;